.DashboardContent__dashboard {
    min-width: 900px;
    position: relative;
    padding-bottom: 200px;
    margin-left: -56px;
    margin-right: -44px;
}
.DashboardContent__container {
    display: flex;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}
.DashboardContent__owner {
    width: 48px;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
}
.DashboardContent__owner div {
    transform-origin: bottom left;
    transform: rotate(-90deg);
    /*white-space: nowrap;*/
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 44px;
    bottom: 0;
    left: 44px;
    width: 0;
    /*background: #62c673;*/
    /*text-align: center;*/
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.DashboardContent__owner p {
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    padding: 0 8px;
    max-height: 40px;
}

.DashboardContent__columnContainer {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    padding-left: 12px;
    padding-right: 28px;
    min-height: 200px;
}
.DashboardContent__column {
    width: 146px;
}
