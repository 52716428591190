.LinePopper__root {
    position: fixed;
    display: flex;
    width: 110px;
    height: 44px;
    padding: 5px;
    opacity: 0.85;
    border-radius: 5px;
    background-color: #2f518f;
    z-index: 100;
}
.LinePopper__panel {
    width: 50%;
}
.LinePopper__panel.left {
    padding-right: 5px;
    border-right: 1px solid rgba(255,255,255,0.3);
}
.LinePopper__panel.right {
    padding-left: 5px;
}
.LinePopper__panel p {
    font-size: 8px;
}
.LinePopper__panel .title {
    font-size: 9px;
    font-weight: 600;
}
