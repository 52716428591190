.ContextMenu__root {
    position: fixed;
    /*display: flex;*/
    min-width: 110px;
    max-width: 240px;
    /*height: 60px;*/
    border-radius: 6px;
    padding: 8px 10px;
    background-color: #2e3b62;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    z-index: 200;
}
.ContextMenu__presetsContainer, .ContextMenu__root p {
    padding: 2px 0;
    font-size: 10px;
    cursor: pointer;
    color: #ccc;
}
.ContextMenu__presetsContainer:hover, .ContextMenu__root p:hover {
    color: #fff;
}

.ContextMenu__root a {
    text-decoration: none;
}

.ContextMenu__root p.connected {
    color: #46af5a;
}

.ContextMenu__root p.connected:hover {
    color: #93e3a0;
}
.ContextMenu__presets {
    display: none;
    position: absolute;
    width: max-content;
    /*height: 120px;*/
    border-radius: 6px;
    padding: 8px 10px;
    background-color: #2e3b62;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    z-index: 200;
    left: 60px;
    bottom: -10px;
}
.ContextMenu__presetsContainer:hover .ContextMenu__presets {
    display: block;
}