.DashboardContent__heading {
    position: sticky;
    top: 106px;
    background-color: #2E3B63;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 54px;
    padding-right: 28px;
    /*margin-bottom: 10px;*/
    margin-left: -56px;
    margin-right: -44px;
}
.DashboardContent__blank {
    position: absolute;
    background-color: #2E3B63;
    height: 32px;
    top: -32px;
    left: 0;
    right: 0;
}
.DashboardContent__heading button {
    padding: 8px 12px 8px;
}
.DashboardContent__heading button.Mui-disabled {
    color: rgba(255, 255, 255, 0.3);
}
.DashboardContent__columnTitle {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    width: 146px;
    white-space: nowrap;
}
