.SvgGraph__root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.SvgGraph__line {
    stroke-width: 1px;
    fill: none;
}
.SvgGraph__line.Selected {
    stroke-width: 2px;
}
.SvgGraph__group:hover .SvgGraph__line {
    stroke-width: 2px;
}
.SvgGraph__button {
    opacity: 0;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}
.SvgGraph__button circle {
    fill: #7898cd;
    stroke-width: 2px;
}
.SvgGraph__button path {
    fill: none;
    stroke: #ed2d5b;
    stroke-width: 2px;
}
.SvgGraph__group:hover .SvgGraph__button {
    opacity: 1;
}
.SvgGraph__button.ShowButton {
    opacity: 1;
}

