.MatrixItem__node {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    width: 146px;
    height: 46px;
    margin: 11px 0;
    border-radius: 14px;
    background-color: #434a8a;
    position: relative;
    transition: all .1s ease-in-out;
}
.MatrixItem__node:hover {
    background-color: #5d5fBa;
}
/*.MatrixItem__node:hover .MatrixItem__iconContainer {*/
/*    padding-right: 4px;*/
/*}*/
.MatrixItem__node.Selected {
    border-style: solid;
    border-width: 2px;
    background-color: #5d5fBa;
}
.MatrixItem__sideLine {
    width: 5px;
    height: 46px;
    background-color: #62c673;
}
.MatrixItem__sideLine.Disabled {
    background-color: #ed2d5b;
}
.MatrixItem__sideLine.Unknown {
    background-color: rgba(144,144,144, 0.8);
}
.MatrixItem__nodeContent {
    flex: 1 1 auto;
    padding: 3px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    white-space: nowrap;
    overflow: hidden;
}
.MatrixItem__nodeContent h4 {
    font-size: 10px;
    font-weight: 500;
    /*max-width: 92px;*/
    overflow: hidden;
    white-space: nowrap;
}
.MatrixItem__nodeContent p {
    font-size: 8px;
    font-weight: 400;
}
.MatrixItem__transferName {
    margin-top: 2px;
    max-width: 94px;
    overflow: hidden;
}
.MatrixItem__iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 8px 2px 0;
}
